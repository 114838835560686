
import React, { Suspense,Component } from 'react';
import { BrowserRouter, Switch, Route,Redirect } from 'react-router-dom';
import Main from './Main'; // fallback for lazy pages
import './App.css';
import './mystyle.css';
import './responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

// const { PUBLIC_URL } = process.env;
const Commercial = React.lazy(() => import('./pages/Commercial'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Policy = React.lazy(() => import('./pages/Policy'));
const Termsofuse = React.lazy(() => import('./pages/termsofuse'));
const Disclaimer = React.lazy(() => import('./pages/disclaimer'));
const Privacy = React.lazy(() => import('./pages/privacy'));
const News = React.lazy(() => import('./pages/News'));
const Index = React.lazy(() => import('./pages/Index'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const Blogs = React.lazy(() => import('./pages/Blogs'));
const Blog = React.lazy(() => import('./pages/Blog'));
const EnquireUs = React.lazy(() => import('./pages/EnquireUs'));
const Test = React.lazy(() => import('./pages/Test'));


class App extends Component {
  constructor(){
    super();
    this.state = 
        {
            routeProjectId:'',
            routeBlogId:''
        }

  //local
    // global.projectUrl='http://192.168.10.52:3000/';
    // global.apiUrl='http://192.168.10.53/sd_developers_interface/index.php/';
    // global.imageUrl='http://192.168.10.53/sd_developers/static/images/';
    // global.videoUrl='http://192.168.10.53/sd_developers/static/videos/';
    // global.imageUploadUrl='http://192.168.10.53/sd_cms/assets/uploads/';
    // global.bannerUploadUrl='http://192.168.10.53/sd_cms/assets/sliders/';
    
  //staging
    global.projectUrl='https://staging.sddevelopers.co.in/';
    global.apiUrl='https://staging.sddevelopers.co.in/api/index.php/';
    global.imageUrl='https://staging.sddevelopers.co.in/static/images/';
    global.videoUrl='https://staging.sddevelopers.co.in/static/videos/';
    global.imageUploadUrl='https://staging.sddevelopers.co.in/cms/assets/uploads/';
    global.bannerUploadUrl='https://staging.sddevelopers.co.in/cms/assets/sliders/';
    
  //live
    // global.projectUrl='https://sddevelopers.co.in/';
    // global.apiUrl='https://sddevelopers.co.in/api/index.php/';
    // global.imageUrl='https://sddevelopers.co.in/static/images/';
    // global.videoUrl='https://sddevelopers.co.in/static/videos/';
    // global.imageUploadUrl='https://sddevelopers.co.in/cms/assets/uploads/';
    // global.bannerUploadUrl='https://sddevelopers.co.in/cms/assets/sliders/';
  }

  componentWillMount() 
  {

            window.scrollTo(0, 0);
            var param=window.location.href.split("/");
           
             var splitParam='';
             
              if(param[3]!=='commercial')
              splitParam=param[3];
              else{
                splitParam=param[4];
                // if(localStorage.getItem('project_id') == null){
                //   localStorage.setItem('project_id', '4')
                // }
              }
             
              

              axios.get(global.apiUrl+'/Project/getProjectbanners?project_id='+splitParam)
              .then(res => {
                const routeProjectId=res.data.banner.project_cat_id;
                this.setState({ routeProjectId });
              });    
             

          if(splitParam==='blogs')
          {
            splitParam=param[4];
       
            axios.get(global.apiUrl+'/Blogs/getblogbyId?blog_id='+splitParam)
            .then(res => {
           
              const routeBlogId=res.data.blog_details;
              this.setState({ routeBlogId });
            });   
          }

  }



  render() {

  
    return(
  <BrowserRouter basename={"/"} onUpdate={() => window.scrollTo(0, 0)} >
    <Suspense fallback={<Main />}>

      <Switch>
      
            <Route exact name="Home" path="/" component={Index} render={props => <Index {...props}/>}/>
            <Route exact name="Commercial" path="/Commercial" component={Commercial} render={props => <Commercial {...props}/>} />
            <Route exact name="privacy" path="/privacy-policy" component={Privacy} render={props => <Privacy {...props}/>}/>
            <Route exact name="Policy" path="/Policy" component={Policy} render={props => <Policy {...props}/>}/>
            <Route exact name="termsofuse" path="/terms-of-use" component={Termsofuse} render={props => <Termsofuse {...props}/>}/>
            <Route exact name="disclaimer" path="/disclaimer" component={Disclaimer} render={props => <Disclaimer {...props}/>}/>
            <Route exact name="News" path="/News" component={News} render={props => <News {...props}/>}/>
            <Route exact name="AboutUs" path="/about-us" component={AboutUs} render={props => <AboutUs {...props}/>}/>
            <Route exact name="Blogs" path="/blogs" component={Blogs} render={props => <Blogs {...props}/>}/>
            {/* <Route exact name="Blog" path="/blog" component={Blog} render={props => <Blog {...props}/>}/> */}
            <Route exact name="EnquireUs" path="/contact-us" component={EnquireUs} render={props => <EnquireUs {...props}/>}/>
            <Route exact name="Test" path="/Test" component={Test} render={props => <Test {...props}/>}/>
            <Route exact name="404" path="/NotFound" component={NotFound} render={props => <NotFound {...props}/>}/>
            <Route exact path="/blogs/:id" component={this.state.routeBlogId==='Null'?NotFound:Blog}  render={props => <Blog {...props}/>} />
            <Route path="/commercial/:id" component={this.state.routeProjectId==='2'?Commercial:NotFound} render={props => <Commercial {...props}/>} />
            <Route path="/:id" component={this.state.routeProjectId==='1'?Index:NotFound} render={props => <Index {...props}/>} />
            
            <Route exact path="*" >
            <Redirect to="/NotFound" /> 
            </Route>
         

      </Switch>
      
    </Suspense>
</BrowserRouter>
    )
  }
}

export default App;

